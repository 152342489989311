<div class="celum-systembar_wrapper">
  <systembar-service-tabs *ngIf="systembar?.services" [services]="systembar.services"></systembar-service-tabs>

  <div class="celum-systembar_growing-place-holder"></div>

  <ng-content class="center-content" select="[notifications]"></ng-content>

  <systembar-avatar class="celum-systembar_components" [avatar]="systembar?.avatar" [languages]="systembar?.languages" [user]="systembar?.user">
    <ng-content select="[small-avatar]" ngProjectAs="[small-avatar]"></ng-content>
    <ng-content select="[big-avatar]" ngProjectAs="[big-avatar]"></ng-content>
  </systembar-avatar>

  <systembar-help class="celum-systembar_components" [contextMenuItems]="systembar?.helpContextMenuItems"></systembar-help>

  <div class="celum-systembar_celum-logo-wrapper">
    <celum-icon [configuration]="celumIconConfiguration"></celum-icon>
  </div>
</div>
